$(document).ready(function(){

    date = moment().format('DD-MM-YYYY');

    birth_date = moment().subtract(17, 'years').format('DD-MM-YYYY');

    // $(document).on('click','.datepicker',function (e) {
    //     $(".datepicker-inline").remove();
    // });

    $('.issue-datepicker').datepicker({
        format: "dd-mm-yyyy",
        endDate: date,
        autoClose: true,
        startView: 2
    }).on('changeDate', function(ev) {
        if(ev.viewMode === 0){
            $('.issue-datepicker').datepicker('hide');
        }
   });

    $('.birth-date-datepicker').datepicker({
        format: "dd-mm-yyyy",
        endDate: birth_date,
        autoClose: true,
        startView: 2
    }).on('changeDate', function(ev) {
        if(ev.viewMode === 0){
            $('.birth-date-datepicker').datepicker('hide');
        }
   });

    $('.datepicker').datepicker({
        format: "dd-mm-yyyy",
        startDate: date,
        autoClose: true,
        defaultDate: date,
        startView: 2
    }).on('changeDate', function(ev) {
        if(ev.viewMode === 0){
            $('.datepicker').datepicker('hide');
        }
   });

    $('.m-y-datepicker').datepicker({
        format: "dd-mm-yyyy",
        startDate: date,
        autoClose: true,
        startView: 2
    }).on('changeDate', function(ev) {
        if(ev.viewMode === 1){
            $('.m-y-datepicker').datepicker('hide');
        }
    });

    var loadDetails = false;
    $(document).on("keyup", ".pin_code_fetch_input", function(){
        var value = $(this).val();
        var form_id = $(this).data("form-id");
        var block_index = $(this).attr('block-index');


        $(".pincode-error-message").remove();

        if($.isNumeric(value) && value.length == 6){
            while(loadDetails == false) {
                $(".pincode-loader-"+block_index).removeClass("hide");
                var url = $("#api-pincode-related-data").val();
                var pincode = value;
                loadDetails = true;
                $.ajax({
                    url : url,
                    method : "GET",
                    data : {
                        pincode : pincode
                    },
                    statusCode : {
                        200 : function(response) {

                            if(response.result.length > 0) {
                                $("#" + form_id).find(".pincode-block-"+block_index+" .pincode-id").val(response.result[0].id);

                                $(".pincode-loader-"+block_index).addClass("hide");
                                // state
                                var states_list = response.result[0].pincodes_states;
                                $("#" + form_id).find("select[name='state["+block_index+"]'] option").remove();


                                if(states_list.length == 1) {

                                    for(var s = 0; s < states_list.length; s++) {
                                        var s_id = states_list[s].state.id;
                                        str = states_list[s].state.name.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                            return letter.toUpperCase();
                                        });
                                        var s_name = str;
                                        $("#" + form_id).find("select[name='state["+block_index+"]']").append("<option value='"+s_id+"' selected>"+s_name+"</option>");
                                    }

                                } else {

                                    $("#" + form_id).find("select[name='state["+block_index+"]']").append("<option value='' selected> Select a state </option>");
                                    for(var s = 0; s < states_list.length; s++) {
                                        var s_id = states_list[s].state.id;
                                        str = states_list[s].state.name.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                            return letter.toUpperCase();
                                        });
                                        var s_name = str;
                                        $("#" + form_id).find("select[name='state["+block_index+"]']").append("<option value='"+s_id+"'>"+s_name+"</option>");
                                    }

                                }

                                // city
                                var cities_list = response.result[0].pincodes_cities;
                                $("#" + form_id).find("select[name='city["+block_index+"]'] option").remove();
                                if(cities_list.length == 1) {

                                    for(var c = 0; c < cities_list.length; c++) {
                                        var c_id = cities_list[c].city.id;
                                        str = cities_list[c].city.name.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                            return letter.toUpperCase();
                                        });
                                        var c_name = str;
                                        $("#" + form_id).find("select[name='city["+block_index+"]']").append("<option value='"+c_id+"' selected>"+c_name+"</option>");
                                    }

                                } else {

                                    $("#" + form_id).find("select[name='city["+block_index+"]']").append("<option value='' selected> Select a city </option>");

                                    for(var c = 0; c < cities_list.length; c++) {
                                        var c_id = cities_list[c].city.id;
                                        str = cities_list[c].city.name.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                            return letter.toUpperCase();
                                        });
                                        var c_name = str;
                                        $("#" + form_id).find("select[name='city["+block_index+"]']").append("<option value='"+c_id+"'>"+c_name+"</option>");
                                    }

                                }

                            } else {

                                // state
                                $("#" + form_id).find("select[name='state["+block_index+"]'] option").remove();
                                $("#" + form_id).find("select[name='state["+block_index+"]']").append("<option value='' selected disabled> Select a state </option>");

                                // city
                                $("#" + form_id).find("select[name='city["+block_index+"]'] option").remove();
                                $("#" + form_id).find("select[name='city["+block_index+"]']").append("<option value='' selected disabled> Select a city </option>");

                                // area
                                // $("#" + form_id).find("select[name='area["+block_index+"]'] option").remove();
                                // $("#" + form_id).find("select[name='area["+block_index+"]']").append("<option value='' selected disabled> Select a area </option>");
                            }
                        },
                        400 :  function(response) {
                            $(".pincode-loader-"+block_index).addClass("hide");
                            $("#"+form_id +" .pin_code_fetch_input").closest("div").append("<div class='pincode-error-message'>Invalid pincode. Please enter a correct pin code.</div>");
                            // state
                            $("#"+form_id).find("select[name='state["+block_index+"]'] option").remove();
                            $("#"+form_id).find("select[name='state["+block_index+"]']").append("<option value=''>Select a state</option>");

                            // city
                            $("#"+form_id).find("select[name='city["+block_index+"]'] option").remove();
                            $("#"+form_id).find("select[name='city["+block_index+"]']").append("<option value=''>Select a city</option>");

                            // area
                            // $("#"+form_id).find("select[name='area["+block_index+"]'] option").remove();
                            // $("#"+form_id).find("select[name='area["+block_index+"]']").append("<option value=''>Select a area</option>");

                        }
                    }
                });
            }
        } else if($.isNumeric(value) && value.length < 6) {
            loadDetails = false;
            $(".pincode-loader"+block_index).addClass("hide");

        }
    });
    
    if($("#current-route-name").val() == 'site.show.company.details'){

        var side_bar_column_width = $("#side-navbar").outerWidth();
        var side_bar_custom_width = side_bar_column_width - 15;

        if(side_bar_column_width < 177 && side_bar_custom_width < 157){
            console.log(side_bar_column_width,side_bar_custom_width);
            $(".navbar-container").css('display','none');
            $("#main-data").removeClass('col-md-2').addClass('col-md-12').css('padding','0px 20px');
            $(".dashboard-menu").show();
        }
        // $(".navbar-container").css('width', side_bar_custom_width+'px');
    }

    $("#state").on('change', function(e){
        e.preventDefault();
        var temp_url = $("#api-admin-state-list").val();
        var url_arr = temp_url.split("%");
        var url = url_arr[0] + parseInt($(this).val());

        $.ajax({
                type: "get",
                url: url,
                statusCode: {
                    200:function (response) {
                        $("#city option").remove();
                        $("#city").append("<option value=''>Select City</option>");
                        $.each(response,function(index,value){
                            $("#city").append('<option value='+value.id+'>'+value.name+'</option>');
                        }); 
                    },
                    400:function (response) {
                       
                    },
                    422:function (response) {

                    }
                }
            });
    });

});