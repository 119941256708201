// global variable

var jcrop_api;
var minimumProfilePicSize = 200 * 200;
var profilePicMinCropSize = { width: 200, height: 200 };

var image_width = 0;
var image_height = 0;

var xsize = $('.registration-profile-image').outerWidth(),
    ysize = $('.registration-profile-image').outerHeight(),
    crop_type = '';
    pic_type = '';


// ----------------- profile pic cropping ------------------------------------------------------------

function showToastr(message,type,closeButton,timeOut,title){
    toastr.options.timeOut = timeOut;
    toastr.options.closeButton = closeButton;

    if(typeof title != 'undefined'){

        if(type == 'success'){
            toastr.success(message,title);
        } else if(type == 'error') {
            toastr.error(message,title);
        } else if(type == 'info') {
            toastr.info(message,title);
        }

    } else {

        if(type == 'success'){
            toastr.success(message);
        } else if(type == 'error') {
            toastr.error(message);
        } else if(type == 'info') {
            toastr.info(message);
        }
    }

}


// -------- Reset profile pic crop area -----

function resetProfilePicCropArea(destroy_thisFormID) {
    if (typeof jcrop_api != 'undefined') {
        jcrop_api.destroy();
        jcrop_api = undefined;
        if(destroy_thisFormID)
            thisFormID = undefined;
        $('#profile-pic-crop-area').html('<div style="position:relative;max-width: 100%;margin-right:auto;margin-left:auto;"><img id="profile-pic-preview" src="" style="max-width: 100%;"></div>');
    }
}


// --- update  crop details ---

function updateCropDetails(e) {
    $('#'+thisFormID+' input[name="image-x"]').val(e.x);
    $('#'+thisFormID+' input[name="image-y"]').val(e.y);
    $('#'+thisFormID+' input[name="image-x2"]').val(e.x2);
    $('#'+thisFormID+' input[name="image-y2"]').val(e.y2);
    $('#'+thisFormID+' input[name="crop-w"]').val(e.w);
    $('#'+thisFormID+' input[name="crop-h"]').val(e.h);

    if( crop_type == 'logo' ) {
        xsize = e.w;
        ysize = e.h;

        if( e.w > 200 ) {
            xsize = 200;
        }

        if( e.h > 200 ) {
            ysize = 200;
        }
        $('#'+thisFormID+' .registration-profile-image #image-content').css({'width' : xsize+'px', 'height' : ysize+'px'});
    }

    // console.log(xsize, ysize, e.w, e.h,e.x,e.y);

    if(e.w > 0) {
        var rx = xsize / e.w;
        var ry = ysize / e.h;

        var new_width = Math.round(rx * image_width) + 'px';
        var new_height = Math.round(ry * image_height) + 'px';
        if( crop_type == 'logo' ){
            // new_width = Math.round(e.w)+'px';
            // new_height = Math.round(e.h)+'px';
        }

        // $('#'+thisFormID+' .registration-profile-image #preview').css({
        //     width: new_width,
        //     height: new_height,
        //     marginLeft: '-' + Math.round(rx * e.x) + 'px',
        //     marginTop: '-' + Math.round(ry * e.y) + 'px'
        // });
    }


}



// -------- profile pic handler

function profilePicSelectHandler(formID, inputName, type , pictype)
{
    thisFormID = formID;

    $('#'+thisFormID+' .registration-profile-image #preview').attr('src', $('#'+thisFormID+' .registration-profile-image #preview').attr('avatar-holder-src')).css({
       'max-width': '100%',
       'width': 'auto',
       'margin-left': 0,
       'margin-top': 0,
       'height': 'auto',
    });

    $('#'+thisFormID+' .registration-profile-image #image-content').css({'width' : '150px'});


    image_width = 0;
    image_height = 0;

    crop_type = type;
    pic_type = pictype;

    if(typeof type != 'undefined' && type == 'logo') {
        minimumProfilePicSize = 50 * 50;
        profilePicMinCropSize = { width: 100, height: 50 };  
    } else {
        minimumProfilePicSize = 200 * 200;
        profilePicMinCropSize = { width: 200, height: 200 };
    }
    
    $(".jcrop-handle").css({'width' : '10px !important','height' : '10px !important'});

    // get selected file
    var oFile = $('#'+thisFormID+' input[name="'+ inputName +'"]')[0].files[0];

    if(typeof oFile == 'undefined')
        return false;

    // check for image type (jpg and png are allowed)
    var rFilter = /^(image\/jpeg|image\/png|image\/bmp|image\/jpg)$/i;
    if (! rFilter.test(oFile.type)) {
        showToastr('Please select a valid image file (jpg, jpg, png, bmp are allowed).', 'error', true, 2000);
        return;
    }

    //check for the image size 1 MB

    var FileSize = oFile.size / 1024 / 1024; // in MB
    if (FileSize > 5) {
        showToastr('File size exceeds 5 MB.', 'error', true, 2000);
        return;
    }

    // destroy Jcrop if it is existed
    resetProfilePicCropArea(false);

    // preview element
    //var oImage = document.getElementById('profile-pic-preview');

    var oImage = document.getElementById('profile-pic-preview');

    // prepare HTML5 FileReader
    var oReader = new FileReader();
    oReader.onload = function(e) {
        // e.target.result contains the DataURL which we can use as a source of the image
        oImage.src = e.target.result;
        oImage.onload = function () { // onload event handler
            // check for image dimensions
            if (this.width * this.height < minimumProfilePicSize && typeof onlyPreview == 'undefined') {
                if(typeof type != 'undefined' && type == 'logo') {
                   showToastr('Please select an image having minimum dimension 50 * 50', 'error', true, 2000);
                } else {
                    showToastr('Please select an image having minimum dimension 200 * 200', 'error', true, 2000);
                }
                this.src = '';
                return;
            }

            if(typeof onlyPreview == 'undefined') {
                $('#profile-photo-modal').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                $('#profile-photo-modal').modal('show');

                //$('#'+thisFormID).find('.fileupload-preview').html(oFile.name);

                $('#'+thisFormID+' input[name^="image-"]').val('');
                $('#'+thisFormID+' input[name^="crop-"]').val('');

                //hide avatar holder
                $('#avatar-holder').hide();
                $('#profile-pic-crop-area').show();

                setTimeout(function(){
                    // initialize Jcrop

                    if(typeof type != 'undefined' && type == 'logo') {
                        var jcrop_config_options = {
                            minSize: [profilePicMinCropSize.width, profilePicMinCropSize.height], // min crop size
                            // maxSize: [200, 200], // max crop size
                            bgFade: true, // use fade effect
                            bgOpacity: .3, // fade opacity
                            allowMove: true,
                            onChange: updateCropDetails,
                            // onSelect: updateCropDetails,
                            onRelease: function() {
                                $('#'+formID+' input[name="image-x"]').val('');
                                $('#'+formID+' input[name="image-y"]').val('');
                            } 
                        };
                    } else {
                        var jcrop_config_options = {
                            minSize: [profilePicMinCropSize.width, profilePicMinCropSize.height], // min crop size
                            aspectRatio : 1, // keep aspect ratio 1:1
                            // maxSize: [200, 200],
                            bgFade: true, // use fade effect
                            bgOpacity: .3, // fade opacity
                            allowMove: true,
                            // onSelect: updateCropDetails,
                            onChange: updateCropDetails,
                            onRelease: function() {
                                $('#'+formID+' input[name="image-x"]').val('');
                                $('#'+formID+' input[name="image-y"]').val('');
                            }
                        };
                    }

                    $('#profile-pic-crop-area div').Jcrop(jcrop_config_options, function() {
                        $('#profile-pic-crop-area .jcrop-holder>div').show();
                        showToastr('please crop the image and upload', 'info', true, 2000);
                        $('#'+thisFormID+' input[name="image-w"]').val($('#profile-pic-preview').width());
                        $('#'+thisFormID+' input[name="image-h"]').val($('#profile-pic-preview').height());

                        jcrop_api = this;

                        image_width = $("#profile-pic-crop-area").width();
                        image_height = $("#profile-pic-crop-area").height();

                        jcrop_api.setSelect([0,0,profilePicMinCropSize.width, profilePicMinCropSize.height]);
                        $("#"+thisFormID+" .profile_pic_text").hide();
                        //$("#"+thisFormID+" .upload-photo-container").css('border','0px');
                    });
                   
                }, 300);
            } else {
                $(onlyPreview).siblings('.preview').attr('src', $('#profile-pic-preview').attr('src'));
            }


        };
    };

    oReader.readAsDataURL(oFile);
}


// ---- preview image select handler -------
function previewImageSelectHandler(file_input, image_ele, callback)
{
    // get selected file
    var oFile = file_input[0].files[0];

    if(typeof oFile == 'undefined')
        return false;

    // check for image type (jpg and png are allowed)
    var rFilter = /^(image\/jpeg|image\/png|image\/bmp|image\/jpg)$/i;
    if (! rFilter.test(oFile.type)) {
        showToastr('Please select a valid image file (jpg, jpg, png, bmp are allowed).', 'error', true, 3000);
        image_ele.closest('li').remove();
        return;
    }

    image_ele.attr('alt', 'loading...');

    // prepare HTML5 FileReader
    var oReader = new FileReader();

    oReader.onload = function(e) {

        //$('#images-container').prepend('<li class="uploaded-img-preview"><div><img src="' + e.target.result + '"><div><a class="remove-uploaded-img" href="" image-index-"' + file_input_index + '">Remove</a></div></div></li>');

        var img;
        img = document.createElement("img");
        img.src = e.target.result;

        img.onload = function() {
            var canvas, ctx, resizeInfo, thumbnail, _ref, _ref1, _ref2, _ref3;

            canvas = document.createElement("canvas");
            ctx = canvas.getContext('2d');

            resizeInfo = thumnailResizeInfo(img);

            if (resizeInfo.trgWidth == null) {
                resizeInfo.trgWidth = resizeInfo.optWidth;
            }
            if (resizeInfo.trgHeight == null) {
                resizeInfo.trgHeight = resizeInfo.optHeight;
            }

            canvas.width = resizeInfo.trgWidth;
            canvas.height = resizeInfo.trgHeight;

            drawImageIOSFix(ctx, img, (_ref = resizeInfo.srcX) != null ? _ref : 0, (_ref1 = resizeInfo.srcY) != null ? _ref1 : 0, resizeInfo.srcWidth, resizeInfo.srcHeight, (_ref2 = resizeInfo.trgX) != null ? _ref2 : 0, (_ref3 = resizeInfo.trgY) != null ? _ref3 : 0, resizeInfo.trgWidth, resizeInfo.trgHeight);
            thumbnail = canvas.toDataURL("image/png");
            image_ele.attr('src', thumbnail);
            window[callback]();
            //$('#images-container').append('<li class="uploaded-img-preview"><div><img src="' + thumbnail + '"><div><a class="remove-uploaded-img" href="" image-index-"' + file_input_index + '">Remove</a></div></div><input type="file" name="images[' + file_input_index + '][file]" id="image-input-' + file_input_index + '" class="upload-image pos-absolute"><input type="hidden" class="image-order-input" name="images[' + file_input_index + '][order]"></li>');
        };
    };

    oReader.readAsDataURL(oFile);
}

// thumbnail resize function----
function thumnailResizeInfo(file)
{   
    var info, srcRatio, trgRatio;
    info = {
      srcX: 0,
      srcY: 0,
      srcWidth: file.width,
      srcHeight: file.height
    };
    srcRatio = file.width / file.height;
    info.optWidth = 100;
    info.optHeight = 100;
    if ((info.optWidth == null) && (info.optHeight == null)) {
      info.optWidth = info.srcWidth;
      info.optHeight = info.srcHeight;
    } else if (info.optWidth == null) {
      info.optWidth = srcRatio * info.optHeight;
    } else if (info.optHeight == null) {
      info.optHeight = (1 / srcRatio) * info.optWidth;
    }
    trgRatio = info.optWidth / info.optHeight;
    if (file.height < info.optHeight || file.width < info.optWidth) {
      info.trgHeight = info.srcHeight;
      info.trgWidth = info.srcWidth;
    } else {
      if (srcRatio > trgRatio) {
        info.srcHeight = file.height;
        info.srcWidth = info.srcHeight * trgRatio;
      } else {
        info.srcWidth = file.width;
        info.srcHeight = info.srcWidth / trgRatio;
      }
    }
    info.srcX = (file.width - info.srcWidth) / 2;
    info.srcY = (file.height - info.srcHeight) / 2;
    return info;    
}


//  delete vertical squash function --------
detectVerticalSquash = function(img) {
    var alpha, canvas, ctx, data, ey, ih, iw, py, ratio, sy;
    iw = img.naturalWidth;
    ih = img.naturalHeight;
    canvas = document.createElement("canvas");
    canvas.width = 1;
    canvas.height = ih;
    ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    data = ctx.getImageData(0, 0, 1, ih).data;
    sy = 0;
    ey = ih;
    py = ih;
    while (py > sy) {
      alpha = data[(py - 1) * 4 + 3];
      if (alpha === 0) {
        ey = py;
      } else {
        sy = py;
      }
      py = (ey + sy) >> 1;
    }
    ratio = py / ih;
    if (ratio === 0) {
      return 1;
    } else {
      return ratio;
    }
};



// --- draw image IOSFIX function ----
drawImageIOSFix = function(ctx, img, sx, sy, sw, sh, dx, dy, dw, dh) {
    var vertSquashRatio;
    vertSquashRatio = detectVerticalSquash(img);
    return ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh / vertSquashRatio);
};

// ---- save profile pic details
$(document).on('click', '#save-profile-pic-details', function(e) {
       //check if user has cropped the image before uploading
        if(!$('#'+thisFormID+' input[name="image-x2"]').val()) {
           var message = 'Please crop the image before uploading';
           showToastr(message, 'error', true, 2000);
           return;
        }

        var loader = Ladda.create(this);
        loader.start();

        if(thisFormID == 'add-brand-form') {
            $(".error-brand-logo").text("");
        }

        var file = $("#"+thisFormID+" #profile-pic")[0].files[0];
        if(crop_type == 'logo') {
            var url = $("#api-upload-company-logo-route").val();
        } else {
            var url = $("#api-upload-user-profile-route").val();
        }

        var data = new FormData();

        data.append("profile_pic",file);
        data.append("image-x", $('#'+thisFormID+' input[name="image-x"]').val());
        data.append("image-y",$('#'+thisFormID+' input[name="image-y"]').val());
        data.append("image-x2",$('#'+thisFormID+' input[name="image-x2"]').val());
        data.append("image-y2",$('#'+thisFormID+' input[name="image-y2"]').val());
        data.append("image-w",$('#'+thisFormID+' input[name="image-w"]').val());
        data.append("image-h",$('#'+thisFormID+' input[name="image-h"]').val());
        data.append("crop-w",$('#'+thisFormID+' input[name="crop-w"]').val());
        data.append("crop-h",$('#'+thisFormID+' input[name="crop-h"]').val());
        
        if($('#'+thisFormID+' input[name="role"]').length > 0){
            data.append("role",$('#'+thisFormID+' input[name="role"]').val());
        }

        if( crop_type == 'logo' ){
            if( $('#'+thisFormID+' input[name="company_id"]').val() != '' ) {
                data.append("company_id",$('#'+thisFormID+' input[name="company_id"]').val());
            }
            if( $('#'+thisFormID+' input[name="institute_id"]').val() != '' ) {
                data.append("institute_id",$('#'+thisFormID+' input[name="institute_id"]').val());
            }
            if($('#'+thisFormID+' input[name="logged_company_id"]').length > 0) {
                if( $('#'+thisFormID+' input[name="company_id"]').val() == '' ) {
                    data.append("company_id",$('#'+thisFormID+' input[name="logged_company_id"]').val());
                }
            }
        } else {
            if( $('#'+thisFormID+' input[name="user_id"]').val() != '' ) {
                data.append("user_id",$('#'+thisFormID+' input[name="user_id"]').val());
            }
        }

        if( pic_type == 'company'){
            var url = $("#api-company-logo").val();
            if( typeof admin_site != 'undefined'){
                var url = $("#api-admin-company-profile-path").val();
            }
        }
        else if( pic_type == 'institute' ){
            var url = $("#api-institute-logo").val();
            if( typeof admin_site != 'undefined'){
                var url = $("#api-admin-institute-logo").val();
            }
        }
        else{
            var url = $("#api-seafarer-profile-pic").val();
            if( typeof admin_site != 'undefined'){
                var url = $("#api-admin-seafarer-profile-path").val();
            }
        }
        
        if( pic_type == 'company_team' ){
            var url = $("#api-company-team-profile-pic").val();
        }

        if( pic_type == 'agent_team' ){
            var url = $("#api-agent-team-profile-pic").val();
        }
        
        $.ajax({
            url : url,
            method : "POST",
            data : data,
            headers: { 'X-CSRF-TOKEN': $('#'+thisFormID+' input[name="_token"]').val() },
            contentType : false,
            processData : false,
            statusCode : {
                200 : function(response) {
                    $('#'+thisFormID+' .registration-profile-image #preview').attr('src', '/'+response.stored_file);
                    $('#'+thisFormID+' input[name="uploaded-file-name"]').val(response.filename);
                    $('#'+thisFormID+' input[name="uploaded-file-path"]').val(response.stored_file);
                    $('#'+thisFormID+' input[name="image_stored_type"]').val(response.stored_type);
                    $('#'+thisFormID+' input[name="image_stored_file"]').val(response.stored_file);
                    $('#'+thisFormID+' input[name="image_file_name"]').val(response.filename);
                    if( crop_type == 'logo' ){
                        $('#'+thisFormID+' input[name="temp_company_id"]').val(response.company_id);
                    } else {
                        $('#'+thisFormID+' input[name="temp_user_id"]').val(response.user_id);
                    }
                    if($("#"+thisFormID+" img.view-value").length > 0) {
                        $("#"+thisFormID+" img.view-value").attr('src', '/'+response.stored_file);
                    }

                    loader.stop();

                    $('#profile-photo-modal').modal('hide');

                    // toastr.success(response.message, 'Success');
                },
                400 : function(response) {
                    loader.stop();
                    toastr.error(response.responseJSON.message, 'Error');
                }
            }
        });

   });

$(document).on('change', '.advertise-file-uplaod', function(){
    setTimeout(function(){
        $(".fileupload-new.thumbnail img").attr('src',$("#advertise_img img").attr('src'));
    },100);
});

// --- cancel profile pic changes
$(document).on('click', '#cancel-profile-pic-change', function(e) {

   e.preventDefault();

   if($('#'+thisFormID+' .registration-profile-image #preview').attr('src') == null){
        $(".profile_pic_text").show();
        $(".upload-photo-container").css('border','2px dashed #d8d7da');
   }

   $('input[name="profile_photo"]').val('');
   $('#'+thisFormID+' .registration-profile-image #preview').attr('src', $('#'+thisFormID+' .registration-profile-image #preview').attr('avatar-holder-src')).css({
       'max-width': '100%',
       'width': 'auto',
       'margin-left': 0,
       'margin-top': 0,
       'height': 'auto',
   });
   $('#profile-photo-modal').modal('hide');
});


$('#resend-otp-button').on('click', function(){
    $("#resend_otp_Modal").modal();
    $.ajax({
         type: "POST",
         url: $("#api-resend-otp-route").val(),
         data: {
             '_token' : $("input[name='_token']").val()
         },
         statusCode: {
             200:function (response) {
                $('#otp_resend_success').removeClass('hide');
                $("#resend-otp-button").addClass('disabled');
                $('#invalid_mob_error').addClass('hide');
             },
             400:function (response) {
                $('#mob_error_resend_otp').removeClass('hide');
             }
         }
    });
});

$('#resend-otp-button-again').on('click', function(){
    var l = Ladda.create(this);
    $(".alert").addClass('hide');
    l.start();
    $.ajax({
         type: "POST",
         url: $("#api-resend-otp-route").val(),
         data: {
             '_token' : $("input[name='_token']").val()
         },
         statusCode: {
             200:function (response) {
                $('#otp_resend_success').removeClass('hide');
                $("#resend-otp-button").addClass('disabled');
                $('#invalid_mob_error').addClass('hide');
                l.stop();
             },
             400:function (response) {
                $('#mob_error_resend_otp').removeClass('hide');
                l.stop();
             }
         }
    });
});

$('#mob-verification').validate({

    rules:{
        mob_otp: {
            required: true,
            minlength: 6,
            maxlength: 6,
            number: true
        }
    }
});

$("#mob_otp_submit_button").on('click',function(e){
    e.preventDefault();
    if($("#mob-verification").valid()){
        $.ajax({
            type: "POST",
            url: $("#mob-verification").attr('action'),
            data: $("#mob-verification").serialize(),
            statusCode: {
                200:function (response) {

                    if($("#current-route-name").val() == 'site.seafarer.registration'){
                        window.location.href = $("#api-auto-welcome-route").val();
                    }
                    if($("#current-route-name").val() == 'site.seafarer.edit.profile'){
                        window.location.href = $("#api-seafarer-profile-route").val();
                    }
                    toastr.success(response.message, 'Success');
                    setTimeout(function () {
                        location.reload();
                    }, 1000);
                },
                400:function (response) {
                    if(response.responseJSON.status == 'error'){
                        $('#otp_resend_success').addClass('hide');
                        $('#invalid_mob_error').addClass('hide');
                        $('#otp_failure').removeClass('hide');
                        $('#otp_failure').text(response.responseJSON.message);
                    }else{
                        $('#otp_resend_success').addClass('hide');
                        $('#invalid_mob_error').removeClass('hide');
                    }
                }
            }
        });
    }
});

$(document).on('click', '.dashboard-menu', function(e){
        
    e.preventDefault();
    var x = $(".navbar-container");
    if (x.hasClass('slider-add')) {
        x.removeClass("slider-add");
        $(".menubar-btn-down").css("display", 'block');
        $(".menubar-btn-up").css("display", 'none');
        $(".overlay").removeClass("overlay-fadein");
        setTimeout(function () {
          $(".overlay").css("display", 'none');
        }, 200);
    } else {
        x.addClass("slider-add");
        $(".menubar-btn-down").css("display", 'none');
        $(".menubar-btn-up").css("display", 'block');
        $(".overlay").css("display", 'block');
        setTimeout(function () {
          $(".overlay").addClass("overlay-fadein");
        }, 100);
    }
});

$(".overlay").click(function(){
    var x = $(this);
     $(".overlay").removeClass("overlay-fadein");
     $(".navbar-container").removeClass("slider-add");
     $(".overlay").removeClass("overlay-fadein");
     $(".menubar-btn-down").css("display", 'block');
     $(".menubar-btn-up").css("display", 'none');
    setTimeout(function () {
      x.css("display", 'none');
    }, 200);
});

$('#email_verify').on('click', function(){
    $('.resend_email_loader').removeClass('hide');
    $.ajax({
        type: "POST",
        url: $("#api-resend-email-route").val(),
        data: {
            '_token' : $("input[name='_token']").val()
        },
        statusCode: {
            200:function (response) {
                toastr.success(response.message, 'Success');
                $('#otp_resend_success').removeClass('hide');
                $("#resend-otp-button").addClass('disabled');
                $('#invalid_mob_error').addClass('hide');
                $('.resend_email_loader').addClass('hide');
                $('.alert-box-verification-email').addClass('hide');
            },
            400:function (response) {
                $('#mob_error_resend_otp').removeClass('hide');
                $('.resend_email_loader').addClass('hide');
                toastr.error(response.responseJSON.message, 'Error');
            },
            500:function (response) {
                $('.resend_email_loader').addClass('hide');
            }
        }
     });
});

$(window).load(function(e){
    if($("#verify_email").length > 0){
        if($("#verify_email").val() == '0'){
            $(".alert-box-verification-email").removeClass('hide');
        }
    }
    if($("#verify_mobile").length > 0){
        if($("#verify_mobile").val() == '0'){
            $(".alert-box-verification-mob").removeClass('hide');
        }
    }
});

$(document).on('ready', function(){

    $(document).on('click','.check-company-subscription',function (e) {
        e.preventDefault();
        var el = $(this);
        var feature_type = $(this).data('type');
        var extra_data = [];
        extra_data['seafarer_id'] = $(this).attr('seafarer-index');
        
        checkSubscriptionFeatureAvaiblity(feature_type,extra_data);
       $('#feature-avaibility-modal .feature-success-modal-btn').click(function(){
            var data = [];
            var l = Ladda.create(this);

            data['feature'] = feature_type;
            data['seafarer_id'] = extra_data['seafarer_id'];
            var temp_url =$("#api-site-company-candidate-download-cv").val();
            if(temp_url){
                var temp_arr = temp_url.split('%');
                var url = temp_arr[0]+data['seafarer_id'];
            }

            l.start();
            $.ajax({
                url: url,
                type: "post",
                data: {
                    data : data
                },
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                },
                statusCode: {
                    200:function (response) {
                        l.stop();
                        $("#feature-avaibility-modal").modal('hide');
                        if (data['feature'] == feature_1 || data['feature'] == cv_download) {
                            window.open(response.file_path,'_blank');
                        }
                    },
                    400:function (response) {
                       l.stop();
                       $("#feature-avaibility-modal").modal('hide');
                       toastr.error(response.responseJSON.message, 'Error');
                    },
                    422:function (response) {
                       l.stop();
                       $("#feature-avaibility-modal").modal('hide');
                       toastr.error(response.responseJSON.message, 'Error');

                    }
                }
            });
       });
       $('#feature-avaibility-modal .feature-failed-modal-btn').click(function(){
           
       });

        
        // if (feature_type == feature_1) {
        //     var seafarer_id = $(this).attr('seafarer-index');
        // } else if (feature_type == feature_3) {
        //     var feature_action = $(this).attr('data-action');
        // }

        
    });


    // $(".feature-success-modal-btn").on('click',function(e){
    //     var data = [];
    //     var l = Ladda.create(this);

    //     data['feature'] = $(this).attr('data-type');
    //     if (data['feature'] == feature_1) {
    //         data['seafarer_id'] = $(this).attr('data-id');
    //         var temp_url =$("#api-site-company-candidate-download-cv").val();
    //         if(temp_url){
    //             var temp_arr = temp_url.split('%');
    //             var url = temp_arr[0]+data['seafarer_id'];
    //         }
    //     }

    //     l.start();
    //     $.ajax({
    //         url: url,
    //         type: "post",
    //         data: {
    //             data : data
    //         },
    //         headers: {
    //             'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
    //         },
    //         statusCode: {
    //             200:function (response) {
    //                 l.stop();
    //                 $("#feature-avaibility-modal").modal('hide');
    //                 if (data['feature'] == feature_1) {
    //                     window.open(response.file_path,'_blank');
    //                 }
    //             },
    //             400:function (response) {
    //                l.stop();
    //                $("#feature-avaibility-modal").modal('hide');
    //                toastr.error(response.responseJSON.message, 'Error');
    //             },
    //             422:function (response) {
    //                l.stop();
    //                $("#feature-avaibility-modal").modal('hide');
    //                toastr.error(response.responseJSON.message, 'Error');

    //             }
    //         }
    //     });
        
    // });

});

if($(".featured_company_section").length > 0){
    var maxHeight = -1;

    $('.coss_card-contentTitle').each(function() {
        maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    });
    
    $('.coss_card-contentTitle').css('height', maxHeight);
}

function checkSubscriptionFeatureAvaiblity(feature_type,extra_data){

    var url = check_company_subscription_url;
    $.ajax({
        url: url,
        type: "post",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        },
        statusCode: {
            200:function (response) {
                if (response.status == 'success') {
                    $("#feature-avaibility-modal").modal('show');
                    $("#feature-avaibility-modal .modal-plan-name-container .modal-plan-name").html(response.result.plan_name);
                    var feature_html;
                    $.each( response.result.result, function( key, value ) {
                      feature_html += '<tr><td>'+ value.feature.title +'</td><td>'+ value.feature.count+' / '+value.feature.duration_type +'</td><td >'+ value.used +'</td><td >'+ value.available +'</td></tr>';
                    });
                    $("#feature-avaibility-modal .modal-body .table-body").html(feature_html);
                }

                $.each( response.result.result, function( key, value ) {
                  if (key == feature_type) {
                    if (value.status) {
                        $("#feature-avaibility-modal .modal-footer .feature-success-modal-btn").css('display','inline-block');
                        $("#feature-avaibility-modal .msg-error").css('display','none');
                        $("#feature-avaibility-modal .msg-success").css('display','block');

                        if (feature_type == feature_1) {
                            // $("#feature-avaibility-modal .modal-footer .feature-success-modal-btn").attr('data-id',seafarer_id);
                            // $("#feature-avaibility-modal .modal-footer .feature-success-modal-btn").attr('data-type',feature_type);
                            $("#feature-avaibility-modal .msg-success").text('CV download feature limit is available. Click continue to download');
                        } else if(feature_type == feature_3){
                            $("#feature-avaibility-modal .modal-footer .feature-success-modal-btn").attr('data-type',feature_type);
                            $("#feature-avaibility-modal .msg-success").text('Advertisement feature is available. Click continue to add advertisement');
                        }
                        $("#feature-avaibility-modal .msg-error").text('');
                    } else {
                        $("#feature-avaibility-modal .modal-footer .feature-success-modal-btn").css('display','none');
                        $("#feature-avaibility-modal .msg-error").css('display','block');
                        $("#feature-avaibility-modal .msg-success").css('display','none');
                        if (feature_type == feature_1) {
                            // $("#feature-avaibility-modal .modal-footer .feature-success-modal-btn").attr('data-id',seafarer_id);
                            // $("#feature-avaibility-modal .modal-footer .feature-success-modal-btn").attr('data-type',feature_type);
                            $("#feature-avaibility-modal .msg-error").text('CV download feature limit is over');
                        } else if(feature_type == feature_3){
                            $("#feature-avaibility-modal .msg-error").text('Advertisement feature is over, deactivate another advertisement to add or upgrade subscription');
                        }
                        $("#feature-avaibility-modal .msg-success").text('');
                    }

                  }
                });
                
            },
            400:function (response) {
               toastr.error(response.responseJSON.message, 'Error');
            }
        }
    });

    $('#feature-avaibility-modal .feature-success-modal-btn').off('click');
    $('#feature-avaibility-modal .feature-failed-modal-btn').off('click');

}