$(document).ready(function () {

    if ($(".service").is(":visible")) {
        var offsetTop = $('.service .banner_section').innerHeight();

        if (Modernizr.mq('only all and (max-width: 992px)')) {

            var is_scrolling = false;
            setInterval(function () {
                if (is_scrolling == true) {

                    if ($(window).scrollTop() > offsetTop) {
                        $('.dropdown_xs').addClass('fixed_top');
                    } 
                    else {
                        $('.dropdown_xs').removeClass('fixed_top');
                    }
                    is_scrolling = false;
                }
            }, 50);

            $(window).scroll(function () {
                is_scrolling = true;
            });
        }

        if (Modernizr.mq('only all and (min-width: 992px)')) {
            var is_scrolling = false;
            setInterval(function () {
                if (is_scrolling == true) {

                    var sidebar_width = $(".calculate__width").width();
                    if ($(window).scrollTop() > offsetTop) {
                        $(".coss_sideBar").addClass("fixed_sidenav").width(sidebar_width);

                        $('.section_2').find('.sideBarContent').addClass('p_t_93');
                        $('.section_2').find('.slider_wrapper').css({ 'left' : sidebar_width, });

                        // if ($(".coss_footer").visible(true)) {
                        //     $(".coss_sideBar").removeClass("fixed_sidenav");
                        // }
                    } 
                    else {
                        $(".coss_sideBar").removeClass("fixed_sidenav");

                        $('.section_2').find('.sideBarContent').removeClass('p_t_93');
                    }
                    is_scrolling = false;
                }
            }, 50);

            $(window).scroll(function () {
                is_scrolling = true;
            });

            // BOOTSTRAP AFFIX FOR SIDENAV
            var bottomReferenceBox = $('.coss_footer');

            //calculate the bottom position
            var bottomOffLine = $(document).height() - bottomReferenceBox.offset().top - bottomReferenceBox.outerHeight();

            $('.coss_sideBar').affix({
                offset: {
                    top: 66,
                    bottom: bottomOffLine
                }
            });
        }


        // $('.seaman_book_slide').on('click', function () {
        //     // $('.seaman_book').slick('slickGoTo', 'slickCurrentSlide');

        //     $('.seaman_book_slide').removeClass('slick-current');
        //     $(this).addClass('slick-current');
        // });

        // $('.seaman_book').on('afterChange', function(event, slick, currentSlide, nextSlide){
        //   console.log('ok');
        //   // $('.seaman_book').slick('slickGoTo', 'slickCurrentSlide');
        //   // $('.seaman_book_slide').removeClass('slick-current');
        //   //   $(this).addClass('slick-current');
        // });

        var activeSlide   = $('.seaman_book_slide');
            
        activeSlide.on('click', function(event){
            event.preventDefault();

            var $this = $(this);
            if ( !$this.hasClass('active')) {
                activeSlide.removeClass('active');
                $this.addClass('active');
            }
        });

        var activeSlide   = $('.seaman_book_slide');

        activeSlide.on('click', function(event){
            event.preventDefault();

            var $this = $(this);
            if ( !$this.hasClass('active')) {
                activeSlide.removeClass('active');
                $this.addClass('active');
            }
        });

        var activeSlide_coc_tab_slide   = $('.coc_tab_slide');

        activeSlide_coc_tab_slide.on('click', function(event){
            event.preventDefault();

            var $this = $(this);
            if ( !$this.hasClass('active')) {
                activeSlide_coc_tab_slide.removeClass('active');
                $this.addClass('active');
            }
        });

        var activeSlide_cop_endorsement_tab_slide   = $('.cop_endorsement_tab_slide');

        activeSlide_cop_endorsement_tab_slide.on('click', function(event){
            event.preventDefault();

            var $this = $(this);
            if ( !$this.hasClass('active')) {
                activeSlide_cop_endorsement_tab_slide.removeClass('active');
                $this.addClass('active');
            }
        });

        $('.coss_sideBar-menuItem').on('click',function(event){
            $(window).scrollTop(135);

            setTimeout(function(){
                $('.seaman_book').slick("refresh");
                $('.coc_tab').slick("refresh");
                $('.cop_endorsement_tab').slick("refresh");
            }, 50);

        });

        $('.seaman_book_slide').on('click',function(event){
            event.preventDefault();

            $link = $(this).attr('cardId');

            $('html, body').animate({
                scrollTop: $($link).offset().top - 160
            }, 500);

            if($link == '#liberian'){
                $('.coss_sideBar-menuItem').scrollTop(0);
            }

        });

        $('.coc_tab_slide').on('click',function(event){
            event.preventDefault();

            // $('.coss_sideBar-menuItem').scrollTop(65);

            $link = $(this).attr('cardId');

            $('html, body').animate({
                scrollTop: $($link).offset().top - 160
            }, 500);

        });

        $('.cop_endorsement_tab_slide').on('click',function(event){
            event.preventDefault();

            // $('.coss_sideBar-menuItem').scrollTop(65);

            $link = $(this).attr('cardId');

            $('html, body').animate({
                scrollTop: $($link).offset().top - 160
            }, 500);

        });


        $(document).on('click','.dropdown_xs-menuItem',function (e) {
            setTimeout(function(){ 
                var hash = window.location.hash;
                hash && $('ul.nav a[href="' + hash + '"]').tab('show');

                $(".dropdown_xs-title").addClass('hide');
                $(hash+"-book").removeClass('hide');
                $('.nav-tabs a').click(function (e) {
                    $('#nav-tabs a[href="'+hash+'"]').tab('show');
                    window.location.hash = this.hash;
                });
            }, 50);

        });

        $('.seaman_book').removeClass('page-dont-break');

        $('.seaman_book').slick({
            arrows: true,
            autoplay: false,
            centerMode: false,
            centerPadding: '0',
            slidesToShow: 7,
            slidesToScroll: 1,
            dots: false,
            infinite: false,
            lazyLoad: 'ondemand',
            prevArrow: '<span class="navArrow_prev navArrow"><i class="fa fa-chevron-left"></i></span>',
            nextArrow: '<span class="navArrow_next navArrow"><i class="fa fa-chevron-right"></i></span>',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        centerMode: false,
                        centerPadding: '0',
                        dots: false
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: false,
                        centerPadding: '0',
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        initialSlide: 0,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '0',
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        initialSlide: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '0',
                    }
                }
            ]
        });

        $('.coc_tab').removeClass('page-dont-break');

        $('.coc_tab').slick({
            arrows: true,
            autoplay: false,
            centerMode: false,
            centerPadding: '0',
            slidesToShow: 7,
            slidesToScroll: 1,
            dots: false,
            infinite: false,
            lazyLoad: 'ondemand',
            prevArrow: '<span class="navArrow_prev navArrow"><i class="fa fa-chevron-left"></i></span>',
            nextArrow: '<span class="navArrow_next navArrow"><i class="fa fa-chevron-right"></i></span>',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        centerMode: false,
                        centerPadding: '0',
                        dots: false
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: false,
                        centerPadding: '0',
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        initialSlide: 0,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '0',
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        initialSlide: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '0',
                    }
                }
            ]
        });

        $('.cop_endorsement_tab').removeClass('page-dont-break');

        $('.cop_endorsement_tab').slick({
            arrows: true,
            autoplay: false,
            centerMode: false,
            centerPadding: '0',
            slidesToShow: 7,
            slidesToScroll: 1,
            dots: false,
            infinite: false,
            lazyLoad: 'ondemand',
            prevArrow: '<span class="navArrow_prev navArrow"><i class="fa fa-chevron-left"></i></span>',
            nextArrow: '<span class="navArrow_next navArrow"><i class="fa fa-chevron-right"></i></span>',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        centerMode: false,
                        centerPadding: '0',
                        dots: false
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: false,
                        centerPadding: '0',
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        initialSlide: 0,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '0',
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        initialSlide: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '0',
                    }
                }
            ]
        });
    }
});